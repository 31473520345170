.content {
  width: 100%;
  overflow: hidden;
}

.errorImg {
  width: 3.866667rem;
  height: 3.666667rem;
  margin: 0 auto;
  padding-top: 1.146667rem;
  background: url('./error.png') no-repeat;
  background-position: bottom center;
  background-size: 100%;
}

.errorWords {
  margin-top: 0.373333rem;
  font-size: 0.4rem;
  line-height: 0.48rem;
  text-align: center;
  color: #999999;
}

.button {
  width: 7.253333rem;
  height: 0.373333rem;
  margin: 0.933333rem auto 0 auto;
  padding: 0.4rem 0;
  border-radius: 0.586667rem;
  background: #fef5f5;
  font-size: 0.373333rem;
  font-weight: bold;
  line-height: 100%;
  color: #f54343;
  text-align: center;
}

.button a {
  text-decoration: none;
  color: #f54343;
}