.bottomSlide {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 1.493333rem;
  background: #fdfdfd;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 0.32rem;
  border-top: 1px solid #ededed;
}

:global(.hairlines) .bottomSlide {
  border-top: 0.5px solid #ededed;
}

.logo {
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: url(./logo.png) no-repeat 0 0.266667rem/5.706667rem 0.986667rem;
}

.botton {
  display: block;
  width: 1.6rem;
  height: 0.746667rem;
  background: #f54343;
  color: #fff;
  margin-top: 0.373333rem;
  border-radius: 0.373333rem;
  font-size: 0.32rem;
  line-height: 0.76rem;
  text-align: center;
}