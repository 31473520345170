.content {
  margin: 1.413333rem 0.533333rem 0 0.533333rem;
  overflow: hidden;
}

.content h2 {
  overflow: hidden;
  font-size: 0.533333rem;
  line-height: 0.64rem;
  color: #373737;
  margin-bottom: 0.12rem;
}

.content p {
  font-size: 0.453333rem;
  line-height: 0.64rem;
  color: #1a1a1a;
  text-align: justify;
}

.content .source {
  margin-top: 0.266667rem;
  font-size: 0.32rem;
  line-height: 0.64rem;
  float: right;
  position: relative;
}

.content .source::before {
  content: '';
  position: absolute;
  left: -0.853333rem;
  top: 0.28rem;
  width: 0.733333rem;
  height: 0.04rem;
  background: #999;
}

.time {
  padding-left: 0.8rem;
  background: url('./icon.png') no-repeat;
  background-size: 0.533333rem 0.346667rem;
  background-position: left center;
  font-weight: normal;
}

.source {
  padding-right: 0.8rem;
  background: url('./icon1.png') no-repeat;
  background-size: 0.533333rem 0.346667rem;
  background-position: right center;
}